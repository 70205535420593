html { height: 100%; overflow:auto; }
body { height: 100%; }


input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}


.error-main-content {
	padding-bottom: 0.3em;
	  font-family: 'Montserrat', sans-serif;
	  font-weight: 700;
	  font-size: 10em;
  }
  
.landing-main-content {
  padding-bottom: 0.3em;
    font-family: 'Kaushan Script', cursive;
    font-weight: 400;
    font-size: 4em;
}

.landing-main-content-big {
  padding-bottom: 0.3em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
	font-size: 5em;
}

.landing-main-button {
	color: #8167a9;
    border-color: #8167a9;
}

.landing-main-button:hover {
background-color: rgb(59,130,159)
}

.landing-main-button:active {
	background-color: rgb(59,130,159)
	}

@media screen and (max-width: 1200px){

	.landing-main-content {
		  font-size: 3em;
	  }

  .landing-main-content-big {
	font-size: 4em;
  }
}

@media screen and (max-width: 580px){

	.landing-main-content {
		  font-size: 2em;
	  }

  .landing-main-content-big {
	font-size: 3em;
  }
}

  @media screen and (max-width: 400px){

	.landing-main-content {
		  font-size: 1.5em;
	  }

	  .logo-wrap a {
		  font-size: 15px;
	  }

  .landing-main-content-big {
	font-size: 2em;
  }
}

.landing-note {
	width: 80%;
    margin: auto;
}

p.text {
	font-weight: 600;
    font-size: .75em;
    letter-spacing: 1px;
    line-height: 1;
    color: #c4c3c9ba;
    text-align: center;
    margin-top: 15px;
    font-style: italic;
}





.main-text-color {
	color: #8168a9!important;
}

.applicationCard {
	margin-bottom: 50px;
	background-color: #394450;
	
}

.applicationCardBody {
	background-color: #2c303a;
}

.applicationCard .card-body {
	/* background: #2c303a; */
	/* padding-bottom: 0 !important; */
	background: #303030
}

.applicationCardHeader {
	background-color: #264653;
	font-family: 'Lato', sans-serif;
}

/* .applicationCard .card-body div.col {
	margin-bottom: 2rem;
}


.applicationCard .card-body .col-xl-1,.applicationCard .card-body .col-xl-2,.applicationCard .card-body .col-xl-3,.applicationCard .card-body .col-xl-4,.applicationCard .card-body .col-xl-5,.applicationCard .card-body .col-xl-6,.applicationCard .card-body .col-xl-7,.applicationCard .card-body .col-xl-8,.applicationCard .card-body .col-xl-9,.applicationCard .card-body .col-xl-10,.applicationCard .card-body .col-xl-11,.applicationCard .card-body .col-xl-12 {
	margin-bottom: 2rem;
}

.applicationCard .card-body .col-xl-1:last-child,.applicationCard .card-body .col-xl-2:last-child,.applicationCard .card-body .col-xl-3:last-child,.applicationCard .card-body .col-xl-4:last-child,.applicationCard .card-body .col-xl-5:last-child,.applicationCard .card-body .col-xl-6:last-child,.applicationCard .card-body .col-xl-7:last-child,.applicationCard .card-body .col-xl-8:last-child,.applicationCard .card-body .col-xl-9:last-child,.applicationCard .card-body .col-xl-10:last-child,.applicationCard .card-body .col-xl-11:last-child,.applicationCard .card-body .col-xl-12:last-child {
	margin-bottom: 2rem;
} */


/* .applicationCard .card-body div.row:last-child {
	margin-bottom: 1rem;
} */

.applicationCard .card-body label {
	margin-bottom: .25rem;
	font-weight: 500;
	color: #ffffffe0;
}

.applicationCard .card-body label.optional {
	color: #a5a5a5;
}

span.optional {
	font-style: italic;
    font-weight: 400;
	color: #ffffff89;
}

label {
	margin-bottom: .25rem;
	font-weight: 500;
	color: #ffffffe0;
}


.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-delay: 0.1s;
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}



.applicationCard .card-header {
	background-color: #264653;
	font-family: 'Lato', sans-serif;
	font-size: 1.25rem;
	color: #FFF;
	padding: .5rem 1.25rem;
	
}

.applicationCard .col {
	min-width: 210px !important;
}

input.form-control {
	border-width: 2px;
	/* min-width: 200px; */
	/* margin-bottom: 2rem; */
	/* margin-bottom: 0; */
	margin-bottom: 24px;
    height: 40px;
}



p.applicationError {
    color: #dd3444;
    height: 0;
    margin: 0;
    padding: 0;
	font-weight: 500;
	/* margin-top: -2rem; */
	padding-left: 7px;
	/* margin-top: -26px; */
	margin-top: -25px;
	position: absolute;
	font-size: 12px;
  }
  
p.applicationError::before {
display: inline;
/* content: "⚠ "; */
}


#rentalApplication {
    margin-left: 20px;
    margin-right: 20px;
}

input, label {
    display:block;
}

.textareaCounter {
	font-weight: 500;
    color: #e2e2e2;
	margin-top: 10px;
	text-align: right;
}

.applicationNav {
	position: fixed;
    z-index: 70;
    margin-left: 94px;
    border-left: 1px solid white;
}

.applicationNav a {
color: white;
font-weight: 600;
}

.applicationCard input {
	/* margin-bottom: 24px; */
}




.form-control.is-invalid, .was-validated .form-control:invalid {
	background-color: #ffe2e2;
	background-color: rgba(31,31,31);
	background-image: none;
}






input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #0000003d;
}
input.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #0000003d;
   opacity:  1;
}
input.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #0000003d;
   opacity:  1;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #0000003d;
}
input.form-control::-ms-input-placeholder { /* Microsoft Edge */
   color:    #0000003d;
}

input.form-control::placeholder { /* Most modern browsers support this now. */
   color:    #0000003d;
}


.addPerson {
	height: 167px;
	width: 197px;

	border-width: 2px;
    box-sizing: border-box;
    border-color: rgb(199, 199, 199);
	/* border-style: dashed; */
	/* background-color: #ffffff1f; */


	vertical-align: top;
    display: inline-block;
	text-align: center;
	display: flex;
    justify-content: center;
	align-items: center;
	

	background-color: #303030;
    background-clip: padding-box;
	/* border: 1px solid #444; */
	border-color: #4a4a4a;
    border-radius: 0.3rem;
	outline: 0;
	box-shadow:
        inset 0 0 2px 0 rgba(255,255,255,.4),
        inset 0 0 3px 0 rgba(0,0,0,.4),
        inset 0 0 3px 5px rgba(0,0,0,.05),
		2px 2px 4px 0 rgba(0,0,0,.25);
		
	background-color: #7d463d;

	background-color: #e4e4e4;
	box-shadow: inset 0 0 20px 0 rgb(255 255 255 / 40%), inset 0 0 30px 0 rgb(0 0 0 / 40%), inset 0 0 30px 50px rgb(0 0 0 / 5%), 2px 2px 4px 0 rgb(0 0 0 / 25%);
	cursor: pointer;

	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.addPerson:hover {
	background-color: #c3c3c3;
}

.addPerson:active {
	background-color: #c3c3c3;
	transform: scale(0.98); 
}

.addPersonGuy {
	/* position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
	bottom: 43px;
	font-size: 50px;
	color: white; */

	
    width: 35px;
	height: 35px;
	color: #d6998ab5;
	
	color: #264653;
}

.addPersonTitle {
	/* color: green;
	display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    height: 100%;
	margin-top: 12px; */
	
	display: block;
	font-size: 18px;
    font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: #264653;

}

.addPersonSubTitle {
	/* color: red;
	display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    height: 100%; */
	/* margin-top: 60px; */
	
	display: block;
	font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
	/* color: grey; */
	color: white;
	color: #264653;
}

.addPersonContent {
    font-size: 20px;
}

.addPersonGroup {
	/* padding: 20px;
    border: 1px solid #bbbbbb;
    border-style: dashed; */
}


.mainApplicationContent {
	overflow: hidden;
	overflow-x: hidden;

}


@media screen and (max-width: 1479px){
	.applicationNav {
	  display: none;
	}
  }

.applicationCardNote {
	font-size: 1rem;
    font-style: italic;
    color: #8168a9!important;
    padding-left: 20px;
	display:inline-block;

}

@media screen and (max-width: 767px){
	.applicationCardNote{
		padding-left: 0;
	}
  }


  .addOccupant {
	font-size: 14px;
    font-weight: 500;
    color: #274653;
    text-align: center;
    width: 100%;
  }


  .additionalOccupantDetailsCard {

	height: 167px;
	width: 200px;
	padding: .75rem;
	position: relative;
    /* display: -webkit-box; */
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    /* background-color: #ffffffe8; */
    background-clip: border-box;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: .25rem;
	text-align: left;
	margin-top: 1.25rem;
    margin-bottom: 1.25rem;
	color: #ce8611;
	background-color: rgba(46,46,46);
  }

  .additionalOccupantDetailsCard button {
	z-index: 10;
	display: block;
	padding: .25rem .5rem;
	font-size: .65em;
	/* color: #0d6efd;
	background-color: #fff; */
	border: 1px solid;
	border-radius: .25rem;
  }

.additionalOccupantDetailsCard h5 {
    line-height: 1.2;
	margin-top: 0;
	text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.additionalOccupantDetailsCard .subject {
	color: red
}

.additionalOccupantDetailsCard table {
	font-size: 12px;
	margin-bottom: 10px;
}

.noWrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.aoInfo {
	padding: 1px;
	font-size: 12px;
	font-style: italic;
}

.aoInfo span {
	/* padding-right: 10px; */
	display: inline-block; 
	width: 31px;
	font-weight: 400;
	
}

hr.line {
	margin-top: 3px;
	margin-bottom: 3px;
	border-color: #ce8611;
}


hr.lastLine{
	margin-top: 3px;
    margin-bottom: 10px;
	border-color: #ce8611;
}

.editIcon {
	height: 15px;
	width: 15px;
	padding-right: 2px;
}


/* CARD */
.additionalOccupantDiv .bs-component .card {
    margin-bottom: 1rem;
}
.additionalOccupantDiv .mb-3, .my-3 {
    margin-bottom: 1rem !important;
}
.additionalOccupantDiv .border-dark {
    border-color: #303030 !important;
}
.additionalOccupantDiv .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #303030;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
	color: #fff;
	/* border-color: #00bc8c !important; */
	border: 1px solid #716b6b !important;
	
}

.additionalOccupantDiv .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.additionalOccupantDiv .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #444;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.additionalOccupantDiv .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
	background-color: #303030;
}










.additionalOccupantModal {
	z-index: 6000;
}

.fade.modal-backdrop.additionalOccupantModalBackdrop.show{
	z-index: 5000;
}




.BCA-full-modal  {


position: fixed;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: 3000;
width: 100%;
height: 100%;
/* display: none; */
overflow: scroll;
outline: 0;
/* background: -webkit-gradient(linear,left top, left bottom,color-stop(0, #fff),to(#eef7f4));
background: linear-gradient(
180deg
,#fff 0,#eef7f4 100%); */
background-color: rgba(18,18,18);

}

.BCA-full-modal .modal-dialog {
	height: 100%!important;
    width: 100%!important;
    max-width: 100%!important;
    margin: 0!important;
}

.BCA-full-modal .header-row {
	width: 100%;
	height: 100%;
	margin-right: 0px !important;
    margin-left: 0px !important;

}

.BCA-full-modal .modal-content {
height: 100%;
width: 100%;
}

.BCA-full-modal .vertical-row {
margin-top: auto;
margin-bottom: auto;
}



.BCA-full-modal .modal-container {
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	max-width: none !important;
    width: 100% !important;
    background-color: rgba(29,29,29);
    color: #ffffffe0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.BCA-full-modal .header-left {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-middle {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-right {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-counter {
	font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2em;
}

.BCA-full-modal .modal-header {
    padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}




.BCA-full-modal .backButton {
	font-size: 25px;
    font-weight: 700;
	color: #ffffffe0;
}

.BCA-full-modal .closeButton {
	color: #cccccc;
	font-size: 25px;
    font-weight: 700;
}

.BCA-full-modal .modal-body {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
	width: 95%;
    max-width: 950px;
}




.BCA-full-modal .continueButton {
background-color: rgb(59,130,159);
outline-color: rgb(59,130,159);
    height: 56px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    width: 190px;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    padding: 0;
    border-radius: 0;
    border: none;
    -webkit-transition-property: background-color,color;
    transition-property: background-color,color;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    text-transform: uppercase;
    margin: 2rem auto;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: rgb(59,130,159) 1px solid;
}

.BCA-full-modal {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-delay: 0.1s;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.form-header {
	color: #8167a9;
    font-family: oswald,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
	text-align: center;
	margin-top: 20px;
    padding-top: 30px;
}

.form-subtitle {
	/* color: #666; */
	font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1em;
    line-height: 20px;
    text-transform: initial;
    max-width: 550px;
    text-align: center;
    margin: auto;
    padding: 0 15px;
	margin-bottom: 30px;
	color: #ffffffc9;
}


.modal-content {
	background-color: rgba(18,18,18);
	border: none;

}

.modal-header {
	background-color: rgba(29,29,29);
    color: #ffffffe0;
}

.BCA-full-modal .closeButton {
    color: #484747;
}

.form-control {
    background-color: rgba(31,31,31);
    border: 1px solid rgba(31,31,31);
	color: #ffffffe0;
	font-weight: 500;
}

.applicationCard .card-body {
    background: rgba(46,46,46);
}






input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #ffffff22;
}
input.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: #ffffff22;
   opacity:  1;
}
input.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: #ffffff22;
   opacity:  1;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #ffffff22;
}
input.form-control::-ms-input-placeholder { /* Microsoft Edge */
   color: #ffffff22;
}

input.form-control::placeholder { /* Most modern browsers support this now. */
   color: #ffffff22;
}


input.form-control:focus {
	background-color: #1b1b1b;
    border-color: rgb(129 104 169 / 76%);
    box-shadow: 0 0 0 0.2rem rgb(129 104 169 / 14%);
    color: white;
}

.card {
	border: none !important;
	border-radius: 1rem;
}


.addOccupantButton {
	margin-bottom: 30px;

}

.addOccupantButton button {
	border-radius: 23px;
    color: #ffa600;
    border-color: #ffa600;
	background: none;
    font-weight: 500;
}

.addOccupantButton button:hover:not([disabled]) {

    color: white;
	background: #ffa600;
}





.modal-backdrop.show {
    opacity: unset !important;
}
.modal-backdrop.fade {
    opacity: unset !important;
}

.fade.modal-backdrop.additionalOccupantModalBackdrop.show {
	opacity: .8 !important;
}

.additionalOccupantsApplicationCard .col {
	text-align: -moz-center;
    text-align: -webkit-center;
}

.additionalOccupantsApplicationCard .card-body {
	padding: 0 1.25rem 0 1.25rem;
	background-color: rgb(19 19 19);
}



.boldText {
    font-weight: 500;
}