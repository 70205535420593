@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap');

/* Or in yesno */
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');


html {
  height: 100%;
}
body {
  min-height: 100%;
  overflow-y: auto;
  background-color: rgba(18,18,18);
}


/* Header */
.BCA-header-container.container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: none !important;
    width: 100% !important;
    background-color: rgba(29,29,29);
    color: #ffffffe0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;

    
}

.BCA-header-container .BCA-header-row.row {
    width: 100%;
    height: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.BCA-header-container .vertical-row {
    margin-top: auto;
    margin-bottom: auto;
}

.BCA-header-container .vertical-row {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.BCA-header-container .vertical-row.header-middle.col {
    text-align: center!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2em;
}

.BCA-header-container .closeButton {
    cursor: pointer;
    color: #484747;
    font-size: 25px;
    font-weight: 700;
    padding: 0 !important;
    margin: .375rem .75rem !important;
}

.BCA-header-container .closeButton:hover {
    color: #9f9f9f;
}

.BCA-header-container .backButton {
font-size: 25px;
font-weight: 700;
color: #ffffffe0;
}

.BCA-header-container .backButton {
    color: #ffffff;
}

/* Main Application */
.BCA-application-wrapper {
    /* background-color: rgba(18,18,18); */
    height: 100vh;
    width: 100%;
}

.BCA-application {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    width: 95%;
    max-width: 950px;
    padding-top: 0px;
}

.BCA-application .continueButton {
    background-color: rgb(59,130,159);
    outline-color: rgb(59,130,159);
    height: 56px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    width: 190px;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    padding: 0;
    border-radius: 0;
    border: none;
    transition-property: background-color,color;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    text-transform: uppercase;
    margin: 2rem auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rgb(59,130,159) 1px solid;
}


.BCA-application .continueButton:hover {
    background-color: rgb(19 19 19);
    color: rgb(59,130,159);
}



/* Inputs */
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dd3444cf !important;
}


.BCA-application textarea.form-control {
    border-width: 2px;
    margin-bottom: 24px;
}


.singleQuestion {
    max-width: 250px !important;
}

.skipButton {
    position: absolute !important;
    right: 1.2rem;
    z-index: 3;
    height: 25px;
    width: 70px;
    border-radius: 12.5px;
    background-color: #755ba0;
    color: #fff;
    /* font-family: proxima-nova; */
    /* font-weight: 500; */
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    text-transform: uppercase;
    box-shadow: none;
    border: none !important;
}

.skipButton:hover:not([disabled]) {
    background-color: #5f4a82;
}

/* .skipButton:hover  {
    color: #fff;
    background-color: #624d86;
    border-color: #68518e;
}

button.skipButton:focus{
    color: #fff;
    background-color: #4d3c69;
    border-color: #3f3155;
}

button.skipButton:active{
    color: #fff;
    background-color: #4d3c69 !important;
    border-color: #3f3155 !important;
} */


.yesNoOr {
    margin-bottom: .25rem;
    font-weight: 900;
    color: #ffffffe0;
    margin-left: 7px;
    margin-right: 7px;
    font-family: 'Allura', cursive;
    font-size: 20px;
    width: 36px;
    display: inline-block;
}

.yesNoWrapper {
    padding: 5px;
    width: 172px;
    border: 1px solid red;
    margin-left: auto;
    margin-right: auto;
}

.yesNoMainWrapper {
    margin-bottom: 20px;
}

.yesNoButton {
    width: 53px;
}

.yesNoLabel {
    text-align: center;
}


.yesNoPicker {
    margin-bottom: 24px
}

.spacerHeight {
    height: 25px
}

.datePicker svg.MuiSvgIcon-root {
    color: #ffffffe0;   /* This effects the radio buttons */   
}

.form-control:focus {
    background-color: rgba(31,31,31);
    border: 1px solid rgba(31,31,31);
    color: #ffffffe0;
    font-weight: 500;
    border-color: rgb(129 104 169 / 76%);
    box-shadow: 0 0 0 0.2rem rgb(129 104 169 / 14%);
}




button.addOccupantButton.btn.btn-secondary :not(:disabled):not(.disabled).active, button.addOccupantButton.btn.btn-secondary :not(:disabled):not(.disabled):active, .show>button.addOccupantButton.btn.btn-secondary .dropdown-toggle {
    /* color: #fff; */
    /* background-color: #545b62; */
    /* border-color: #4e555b; */

    color: #ffa600;
    border-color: #ffa600;
    background: none !important;
}


button.addOccupantButton.btn.btn-secondary:focus {
    color: #ffa600;
    border-color: #ffa600;
    background: none !important;

    box-shadow: none !important;
}

button.addOccupantButton.btn.btn-secondary:hover :not(:disabled) {
    color: white;
    border-color: #ffa600;
    background: #ffa600 !important;
}

button.btn.btn-link.ModalCloseButton {
    color: #b1b1b1;
    font-size: 25px;
    font-weight: 700;
    padding: 0 !important;
    margin: .375rem .75rem !important;
    position: absolute;
    top: 0;
    right: 0;
}

.modalButton {
    width: 85px;
}

.center-main-content {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translateY(-50%);
    width: 100%;
    transition: all 500ms linear;
    margin: 0 auto;
    text-align: center;
    color: #e2e2e2;
}

.applicationInfoMain {

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 7vw; */
}

.applicationInfoSub {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 5vw; */
}

.applicationInfoSubInfo {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 3vw; */
    margin-bottom: 100px;
}