@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Allura&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html { height: 100%; overflow:auto; }
body { height: 100%; }


input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}


.error-main-content {
	padding-bottom: 0.3em;
	  font-family: 'Montserrat', sans-serif;
	  font-weight: 700;
	  font-size: 10em;
  }
  
.landing-main-content {
  padding-bottom: 0.3em;
    font-family: 'Kaushan Script', cursive;
    font-weight: 400;
    font-size: 4em;
}

.landing-main-content-big {
  padding-bottom: 0.3em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
	font-size: 5em;
}

.landing-main-button {
	color: #8167a9;
    border-color: #8167a9;
}

.landing-main-button:hover {
background-color: rgb(59,130,159)
}

.landing-main-button:active {
	background-color: rgb(59,130,159)
	}

@media screen and (max-width: 1200px){

	.landing-main-content {
		  font-size: 3em;
	  }

  .landing-main-content-big {
	font-size: 4em;
  }
}

@media screen and (max-width: 580px){

	.landing-main-content {
		  font-size: 2em;
	  }

  .landing-main-content-big {
	font-size: 3em;
  }
}

  @media screen and (max-width: 400px){

	.landing-main-content {
		  font-size: 1.5em;
	  }

	  .logo-wrap a {
		  font-size: 15px;
	  }

  .landing-main-content-big {
	font-size: 2em;
  }
}

.landing-note {
	width: 80%;
    margin: auto;
}

p.text {
	font-weight: 600;
    font-size: .75em;
    letter-spacing: 1px;
    line-height: 1;
    color: #c4c3c9ba;
    text-align: center;
    margin-top: 15px;
    font-style: italic;
}





.main-text-color {
	color: #8168a9!important;
}

.applicationCard {
	margin-bottom: 50px;
	background-color: #394450;
	
}

.applicationCardBody {
	background-color: #2c303a;
}

.applicationCard .card-body {
	/* background: #2c303a; */
	/* padding-bottom: 0 !important; */
	background: #303030
}

.applicationCardHeader {
	background-color: #264653;
	font-family: 'Lato', sans-serif;
}

/* .applicationCard .card-body div.col {
	margin-bottom: 2rem;
}


.applicationCard .card-body .col-xl-1,.applicationCard .card-body .col-xl-2,.applicationCard .card-body .col-xl-3,.applicationCard .card-body .col-xl-4,.applicationCard .card-body .col-xl-5,.applicationCard .card-body .col-xl-6,.applicationCard .card-body .col-xl-7,.applicationCard .card-body .col-xl-8,.applicationCard .card-body .col-xl-9,.applicationCard .card-body .col-xl-10,.applicationCard .card-body .col-xl-11,.applicationCard .card-body .col-xl-12 {
	margin-bottom: 2rem;
}

.applicationCard .card-body .col-xl-1:last-child,.applicationCard .card-body .col-xl-2:last-child,.applicationCard .card-body .col-xl-3:last-child,.applicationCard .card-body .col-xl-4:last-child,.applicationCard .card-body .col-xl-5:last-child,.applicationCard .card-body .col-xl-6:last-child,.applicationCard .card-body .col-xl-7:last-child,.applicationCard .card-body .col-xl-8:last-child,.applicationCard .card-body .col-xl-9:last-child,.applicationCard .card-body .col-xl-10:last-child,.applicationCard .card-body .col-xl-11:last-child,.applicationCard .card-body .col-xl-12:last-child {
	margin-bottom: 2rem;
} */


/* .applicationCard .card-body div.row:last-child {
	margin-bottom: 1rem;
} */

.applicationCard .card-body label {
	margin-bottom: .25rem;
	font-weight: 500;
	color: #ffffffe0;
}

.applicationCard .card-body label.optional {
	color: #a5a5a5;
}

span.optional {
	font-style: italic;
    font-weight: 400;
	color: #ffffff89;
}

label {
	margin-bottom: .25rem;
	font-weight: 500;
	color: #ffffffe0;
}


.animated {
    animation-duration: 1s;
	animation-fill-mode: both;
	animation-delay: 0.1s;
}

.fadeInLeft {
    animation-name: fadeInLeft;
}



.applicationCard .card-header {
	background-color: #264653;
	font-family: 'Lato', sans-serif;
	font-size: 1.25rem;
	color: #FFF;
	padding: .5rem 1.25rem;
	
}

.applicationCard .col {
	min-width: 210px !important;
}

input.form-control {
	border-width: 2px;
	/* min-width: 200px; */
	/* margin-bottom: 2rem; */
	/* margin-bottom: 0; */
	margin-bottom: 24px;
    height: 40px;
}



p.applicationError {
    color: #dd3444;
    height: 0;
    margin: 0;
    padding: 0;
	font-weight: 500;
	/* margin-top: -2rem; */
	padding-left: 7px;
	/* margin-top: -26px; */
	margin-top: -25px;
	position: absolute;
	font-size: 12px;
  }
  
p.applicationError::before {
display: inline;
/* content: "⚠ "; */
}


#rentalApplication {
    margin-left: 20px;
    margin-right: 20px;
}

input, label {
    display:block;
}

.textareaCounter {
	font-weight: 500;
    color: #e2e2e2;
	margin-top: 10px;
	text-align: right;
}

.applicationNav {
	position: fixed;
    z-index: 70;
    margin-left: 94px;
    border-left: 1px solid white;
}

.applicationNav a {
color: white;
font-weight: 600;
}

.applicationCard input {
	/* margin-bottom: 24px; */
}




.form-control.is-invalid, .was-validated .form-control:invalid {
	background-color: #ffe2e2;
	background-color: rgba(31,31,31);
	background-image: none;
}






input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #0000003d;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #0000003d;
}

input.form-control::placeholder { /* Most modern browsers support this now. */
   color:    #0000003d;
}


.addPerson {
	height: 167px;
	width: 197px;

	border-width: 2px;
    box-sizing: border-box;
    border-color: rgb(199, 199, 199);
	/* border-style: dashed; */
	/* background-color: #ffffff1f; */


	vertical-align: top;
    display: inline-block;
	text-align: center;
	display: flex;
    justify-content: center;
	align-items: center;
	

	background-color: #303030;
    background-clip: padding-box;
	/* border: 1px solid #444; */
	border-color: #4a4a4a;
    border-radius: 0.3rem;
	outline: 0;
	box-shadow:
        inset 0 0 2px 0 rgba(255,255,255,.4),
        inset 0 0 3px 0 rgba(0,0,0,.4),
        inset 0 0 3px 5px rgba(0,0,0,.05),
		2px 2px 4px 0 rgba(0,0,0,.25);
		
	background-color: #7d463d;

	background-color: #e4e4e4;
	box-shadow: inset 0 0 20px 0 rgb(255 255 255 / 40%), inset 0 0 30px 0 rgb(0 0 0 / 40%), inset 0 0 30px 50px rgb(0 0 0 / 5%), 2px 2px 4px 0 rgb(0 0 0 / 25%);
	cursor: pointer;

	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.addPerson:hover {
	background-color: #c3c3c3;
}

.addPerson:active {
	background-color: #c3c3c3;
	transform: scale(0.98); 
}

.addPersonGuy {
	/* position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
	bottom: 43px;
	font-size: 50px;
	color: white; */

	
    width: 35px;
	height: 35px;
	color: #d6998ab5;
	
	color: #264653;
}

.addPersonTitle {
	/* color: green;
	display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    height: 100%;
	margin-top: 12px; */
	
	display: block;
	font-size: 18px;
    font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: #264653;

}

.addPersonSubTitle {
	/* color: red;
	display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    height: 100%; */
	/* margin-top: 60px; */
	
	display: block;
	font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
	/* color: grey; */
	color: white;
	color: #264653;
}

.addPersonContent {
    font-size: 20px;
}

.addPersonGroup {
	/* padding: 20px;
    border: 1px solid #bbbbbb;
    border-style: dashed; */
}


.mainApplicationContent {
	overflow: hidden;
	overflow-x: hidden;

}


@media screen and (max-width: 1479px){
	.applicationNav {
	  display: none;
	}
  }

.applicationCardNote {
	font-size: 1rem;
    font-style: italic;
    color: #8168a9!important;
    padding-left: 20px;
	display:inline-block;

}

@media screen and (max-width: 767px){
	.applicationCardNote{
		padding-left: 0;
	}
  }


  .addOccupant {
	font-size: 14px;
    font-weight: 500;
    color: #274653;
    text-align: center;
    width: 100%;
  }


  .additionalOccupantDetailsCard {

	height: 167px;
	width: 200px;
	padding: .75rem;
	position: relative;
    /* display: -webkit-box; */
    display: -ms-flexbox;
    /* display: flex; */
    flex-direction: column;
    word-wrap: break-word;
    /* background-color: #ffffffe8; */
    background-clip: border-box;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: .25rem;
	text-align: left;
	margin-top: 1.25rem;
    margin-bottom: 1.25rem;
	color: #ce8611;
	background-color: rgba(46,46,46);
  }

  .additionalOccupantDetailsCard button {
	z-index: 10;
	display: block;
	padding: .25rem .5rem;
	font-size: .65em;
	/* color: #0d6efd;
	background-color: #fff; */
	border: 1px solid;
	border-radius: .25rem;
  }

.additionalOccupantDetailsCard h5 {
    line-height: 1.2;
	margin-top: 0;
	text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.additionalOccupantDetailsCard .subject {
	color: red
}

.additionalOccupantDetailsCard table {
	font-size: 12px;
	margin-bottom: 10px;
}

.noWrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.aoInfo {
	padding: 1px;
	font-size: 12px;
	font-style: italic;
}

.aoInfo span {
	/* padding-right: 10px; */
	display: inline-block; 
	width: 31px;
	font-weight: 400;
	
}

hr.line {
	margin-top: 3px;
	margin-bottom: 3px;
	border-color: #ce8611;
}


hr.lastLine{
	margin-top: 3px;
    margin-bottom: 10px;
	border-color: #ce8611;
}

.editIcon {
	height: 15px;
	width: 15px;
	padding-right: 2px;
}


/* CARD */
.additionalOccupantDiv .bs-component .card {
    margin-bottom: 1rem;
}
.additionalOccupantDiv .mb-3, .my-3 {
    margin-bottom: 1rem !important;
}
.additionalOccupantDiv .border-dark {
    border-color: #303030 !important;
}
.additionalOccupantDiv .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #303030;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
	color: #fff;
	/* border-color: #00bc8c !important; */
	border: 1px solid #716b6b !important;
	
}

.additionalOccupantDiv .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.additionalOccupantDiv .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #444;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.additionalOccupantDiv .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
	background-color: #303030;
}










.additionalOccupantModal {
	z-index: 6000;
}

.fade.modal-backdrop.additionalOccupantModalBackdrop.show{
	z-index: 5000;
}




.BCA-full-modal  {


position: fixed;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: 3000;
width: 100%;
height: 100%;
/* display: none; */
overflow: scroll;
outline: 0;
/* background: -webkit-gradient(linear,left top, left bottom,color-stop(0, #fff),to(#eef7f4));
background: linear-gradient(
180deg
,#fff 0,#eef7f4 100%); */
background-color: rgba(18,18,18);

}

.BCA-full-modal .modal-dialog {
	height: 100%!important;
    width: 100%!important;
    max-width: 100%!important;
    margin: 0!important;
}

.BCA-full-modal .header-row {
	width: 100%;
	height: 100%;
	margin-right: 0px !important;
    margin-left: 0px !important;

}

.BCA-full-modal .modal-content {
height: 100%;
width: 100%;
}

.BCA-full-modal .vertical-row {
margin-top: auto;
margin-bottom: auto;
}



.BCA-full-modal .modal-container {
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	max-width: none !important;
    width: 100% !important;
    background-color: rgba(29,29,29);
    color: #ffffffe0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.BCA-full-modal .header-left {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-middle {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-right {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.BCA-full-modal .header-counter {
	font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2em;
}

.BCA-full-modal .modal-header {
    padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}




.BCA-full-modal .backButton {
	font-size: 25px;
    font-weight: 700;
	color: #ffffffe0;
}

.BCA-full-modal .closeButton {
	color: #cccccc;
	font-size: 25px;
    font-weight: 700;
}

.BCA-full-modal .modal-body {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
	width: 95%;
    max-width: 950px;
}




.BCA-full-modal .continueButton {
background-color: rgb(59,130,159);
outline-color: rgb(59,130,159);
    height: 56px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    width: 190px;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    padding: 0;
    border-radius: 0;
    border: none;
    transition-property: background-color,color;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    text-transform: uppercase;
    margin: 2rem auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rgb(59,130,159) 1px solid;
}

.BCA-full-modal {
    animation-duration: 1s;
	animation-fill-mode: both;
	animation-delay: 0.1s;
    animation-name: fadeInLeft;
}

.form-header {
	color: #8167a9;
    font-family: oswald,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
	text-align: center;
	margin-top: 20px;
    padding-top: 30px;
}

.form-subtitle {
	/* color: #666; */
	font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1em;
    line-height: 20px;
    text-transform: none;
    text-transform: initial;
    max-width: 550px;
    text-align: center;
    margin: auto;
    padding: 0 15px;
	margin-bottom: 30px;
	color: #ffffffc9;
}


.modal-content {
	background-color: rgba(18,18,18);
	border: none;

}

.modal-header {
	background-color: rgba(29,29,29);
    color: #ffffffe0;
}

.BCA-full-modal .closeButton {
    color: #484747;
}

.form-control {
    background-color: rgba(31,31,31);
    border: 1px solid rgba(31,31,31);
	color: #ffffffe0;
	font-weight: 500;
}

.applicationCard .card-body {
    background: rgba(46,46,46);
}






input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #ffffff22;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #ffffff22;
}

input.form-control::placeholder { /* Most modern browsers support this now. */
   color: #ffffff22;
}


input.form-control:focus {
	background-color: #1b1b1b;
    border-color: rgb(129 104 169 / 76%);
    box-shadow: 0 0 0 0.2rem rgb(129 104 169 / 14%);
    color: white;
}

.card {
	border: none !important;
	border-radius: 1rem;
}


.addOccupantButton {
	margin-bottom: 30px;

}

.addOccupantButton button {
	border-radius: 23px;
    color: #ffa600;
    border-color: #ffa600;
	background: none;
    font-weight: 500;
}

.addOccupantButton button:hover:not([disabled]) {

    color: white;
	background: #ffa600;
}





.modal-backdrop.show {
    opacity: unset !important;
}
.modal-backdrop.fade {
    opacity: unset !important;
}

.fade.modal-backdrop.additionalOccupantModalBackdrop.show {
	opacity: .8 !important;
}

.additionalOccupantsApplicationCard .col {
	text-align: -moz-center;
    text-align: -webkit-center;
}

.additionalOccupantsApplicationCard .card-body {
	padding: 0 1.25rem 0 1.25rem;
	background-color: rgb(19 19 19);
}



.boldText {
    font-weight: 500;
}
/* Or in yesno */


html {
  height: 100%;
}
body {
  min-height: 100%;
  overflow-y: auto;
  background-color: rgba(18,18,18);
}


/* Header */
.BCA-header-container.container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: none !important;
    width: 100% !important;
    background-color: rgba(29,29,29);
    color: #ffffffe0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;

    
}

.BCA-header-container .BCA-header-row.row {
    width: 100%;
    height: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.BCA-header-container .vertical-row {
    margin-top: auto;
    margin-bottom: auto;
}

.BCA-header-container .vertical-row {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.BCA-header-container .vertical-row.header-middle.col {
    text-align: center!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2em;
}

.BCA-header-container .closeButton {
    cursor: pointer;
    color: #484747;
    font-size: 25px;
    font-weight: 700;
    padding: 0 !important;
    margin: .375rem .75rem !important;
}

.BCA-header-container .closeButton:hover {
    color: #9f9f9f;
}

.BCA-header-container .backButton {
font-size: 25px;
font-weight: 700;
color: #ffffffe0;
}

.BCA-header-container .backButton {
    color: #ffffff;
}

/* Main Application */
.BCA-application-wrapper {
    /* background-color: rgba(18,18,18); */
    height: 100vh;
    width: 100%;
}

.BCA-application {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    width: 95%;
    max-width: 950px;
    padding-top: 0px;
}

.BCA-application .continueButton {
    background-color: rgb(59,130,159);
    outline-color: rgb(59,130,159);
    height: 56px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    width: 190px;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    padding: 0;
    border-radius: 0;
    border: none;
    transition-property: background-color,color;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    text-transform: uppercase;
    margin: 2rem auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rgb(59,130,159) 1px solid;
}


.BCA-application .continueButton:hover {
    background-color: rgb(19 19 19);
    color: rgb(59,130,159);
}



/* Inputs */
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dd3444cf !important;
}


.BCA-application textarea.form-control {
    border-width: 2px;
    margin-bottom: 24px;
}


.singleQuestion {
    max-width: 250px !important;
}

.skipButton {
    position: absolute !important;
    right: 1.2rem;
    z-index: 3;
    height: 25px;
    width: 70px;
    border-radius: 12.5px;
    background-color: #755ba0;
    color: #fff;
    /* font-family: proxima-nova; */
    /* font-weight: 500; */
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    text-transform: uppercase;
    box-shadow: none;
    border: none !important;
}

.skipButton:hover:not([disabled]) {
    background-color: #5f4a82;
}

/* .skipButton:hover  {
    color: #fff;
    background-color: #624d86;
    border-color: #68518e;
}

button.skipButton:focus{
    color: #fff;
    background-color: #4d3c69;
    border-color: #3f3155;
}

button.skipButton:active{
    color: #fff;
    background-color: #4d3c69 !important;
    border-color: #3f3155 !important;
} */


.yesNoOr {
    margin-bottom: .25rem;
    font-weight: 900;
    color: #ffffffe0;
    margin-left: 7px;
    margin-right: 7px;
    font-family: 'Allura', cursive;
    font-size: 20px;
    width: 36px;
    display: inline-block;
}

.yesNoWrapper {
    padding: 5px;
    width: 172px;
    border: 1px solid red;
    margin-left: auto;
    margin-right: auto;
}

.yesNoMainWrapper {
    margin-bottom: 20px;
}

.yesNoButton {
    width: 53px;
}

.yesNoLabel {
    text-align: center;
}


.yesNoPicker {
    margin-bottom: 24px
}

.spacerHeight {
    height: 25px
}

.datePicker svg.MuiSvgIcon-root {
    color: #ffffffe0;   /* This effects the radio buttons */   
}

.form-control:focus {
    background-color: rgba(31,31,31);
    border: 1px solid rgba(31,31,31);
    color: #ffffffe0;
    font-weight: 500;
    border-color: rgb(129 104 169 / 76%);
    box-shadow: 0 0 0 0.2rem rgb(129 104 169 / 14%);
}




button.addOccupantButton.btn.btn-secondary :not(:disabled):not(.disabled).active, button.addOccupantButton.btn.btn-secondary :not(:disabled):not(.disabled):active, .show>button.addOccupantButton.btn.btn-secondary .dropdown-toggle {
    /* color: #fff; */
    /* background-color: #545b62; */
    /* border-color: #4e555b; */

    color: #ffa600;
    border-color: #ffa600;
    background: none !important;
}


button.addOccupantButton.btn.btn-secondary:focus {
    color: #ffa600;
    border-color: #ffa600;
    background: none !important;

    box-shadow: none !important;
}

button.addOccupantButton.btn.btn-secondary:hover :not(:disabled) {
    color: white;
    border-color: #ffa600;
    background: #ffa600 !important;
}

button.btn.btn-link.ModalCloseButton {
    color: #b1b1b1;
    font-size: 25px;
    font-weight: 700;
    padding: 0 !important;
    margin: .375rem .75rem !important;
    position: absolute;
    top: 0;
    right: 0;
}

.modalButton {
    width: 85px;
}

.center-main-content {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translateY(-50%);
    width: 100%;
    transition: all 500ms linear;
    margin: 0 auto;
    text-align: center;
    color: #e2e2e2;
}

.applicationInfoMain {

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 7vw; */
}

.applicationInfoSub {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 5vw; */
}

.applicationInfoSubInfo {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* font-size: 3vw; */
    margin-bottom: 100px;
}
/* Welcome to */

.BCA-landing-page {
    /* transition not working */
    transition:all 0.3s ease;
}

.BCA-landing-page .landing-note {
    width: 80%;
    margin: auto;
}

.BCA-landing-page .landing-note p {
    font-weight: 600;
    font-size: .75em;
    letter-spacing: 1px;
    line-height: 1;
    color: #c4c3c9ba;
    text-align: center;
    margin-top: 15px;
    font-style: italic;
}
html { height: 100% !important; overflow:auto !important; }
body { height: 100% !important; }


body{
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.7;
	/* color: #c4c3ca; */
	/* background-color: #1f2029; */
	color: black;
	/* background-color: #131417; */
	overflow-x: hidden;
	/* -webkit-transition: all 300ms linear; */
	/* transition: all 300ms linear; */
	/* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.26' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"); */
	/* background: #222; */
	max-width: 100%;
	overflow-x: hidden;
}
a {
	cursor: pointer;
}
a:hover {
	text-decoration: none;
}

input {
	/* custom */
	caret-color: #9d75d8ba
  }



/* #Primary style
================================================== */

.section {
    position: relative;
	width: 100%;
	display: block;
}
.over-hide{
	overflow: hidden;
}
.full-height {
  height: 100vh;
  top: 0;
  position: absolute;
}

/* #Navigation
================================================== */
 
.cd-header{
    position: relative;
	width:100%;
	top:0;
	left:0;
  z-index:100;
  /* margin-bottom: 20px; */
  height: 84px;
  background: #2e2f50c4;

  /* border-bottom: 1px solid #fff; */
} 
.header-wrapper{
    position: relative;
	width: calc(100% - 100px);
	margin-left: 50px;
} 
.logo-wrap {
	position: absolute;
	display:block;
	left:0;
	top: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 0px;
  margin-left: -20px;
}
.logo-wrap a {
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	font-weight: 900;
	font-size: 20px;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #fff;
	transition : all 0.3s ease-out;
}
.logo-wrap a span{ 
	color: #8167a9;
}
.logo-wrap a:hover {
	opacity: 0.9;
}
.nav-but-wrap{ 
	position: relative;
	display: inline-block;
	float: right;
	padding-left: 15px;
	padding-top: 5px;
	margin-top: 26px;
	transition : all 0.3s ease-out;
	margin-right: -20px;
}
.menu-icon {
	height: 30px;
	width: 30px;
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: block;
}
.menu-icon__line {
	height: 2px;
	width: 30px;
	display: block;
	background-color: #fff;
	margin-bottom: 7px;
	cursor: pointer;
	transition: transform .2s ease, background-color .5s ease;
}
.menu-icon__line-left {
	width: 16.5px;
	transition: all 200ms linear;
}
.menu-icon__line-right {
	width: 16.5px;
	float: right;
	transition: all 200ms linear;
}
.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
	width: 30px;
}
.nav {
	position: fixed;
	z-index: 98;
}
.nav:before, .nav:after {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(20, 21, 26,0.6);
	border-bottom-left-radius: 200%;
	z-index: -1;
	transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s;
 transform: translateX(100%) translateY(-100%);
}
.nav:after {
	background: rgba(9,9,12,1);
 transition-delay: 0s;
}
.nav:before {
 transition-delay: .2s;
}
.nav__content {
	position: fixed;
	visibility: hidden;
	top: 50%;
	margin-top: 20px;
 transform: translate(0%, -50%);
	width: 100%;
	text-align: center;
}
.nav__list {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
}
.nav__list-item {
	position: relative;
	display: block;
 transition-delay: 0.8s;
	opacity: 0;
	text-align: center;
	color: #fff;
	overflow: hidden; 
	font-family: 'Montserrat', sans-serif;
	font-size: 7vh;
	font-weight: 900;
	line-height: 1.15;
	letter-spacing: 3px;
 transform: translate(100px, 0%);
	transition: opacity .2s ease, transform .3s ease;
	margin-top: 0;
	margin-bottom: 0;
}
.nav__list-item a{ 
	position: relative;
	text-decoration: none;
	color: rgba(255,255,255,0.6);
	overflow: hidden; 
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
	font-weight: 900;
	z-index: 2;
	display: inline-block;
	text-transform: uppercase;
    transition: all 200ms linear; 
}
.nav__list-item a:after{ 
	position: absolute;
	content: '';
	top: 50%;
	margin-top: -2px;
	left: 50%;
	width: 0;
	height: 0;
	opacity: 0;
	/* background-color: #8167a9; */
	z-index: 1;
    transition: all 200ms linear; 
}
.nav__list-item a:hover:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}
.nav__list-item a:hover{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav a:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}
body.nav-active .nav__content {
	visibility: visible;
}
body.nav-active .menu-icon__line {
	background-color: #fff;
 transform: translate(0px, 0px) rotate(-45deg);
}
body.nav-active .menu-icon__line-left {
	width: 15px;
 transform: translate(2px, 4px) rotate(45deg);
}
body.nav-active .menu-icon__line-right {
	width: 15px;
	float: right;
 transform: translate(-3px, -3.5px) rotate(45deg);
}
body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
	width: 15px;
}
body.nav-active .nav {
	visibility: visible;
}
body.nav-active .nav:before, body.nav-active .nav:after {
 transform: translateX(0%) translateY(0%);
	border-radius: 0;
}
body.nav-active .nav:after {
 transition-delay: .1s;
}
body.nav-active .nav:before {
 transition-delay: 0s;
}
body.nav-active .nav__list-item {
	opacity: 1;
 transform: translateX(0%);
	transition: opacity .3s ease, transform .3s ease, color .3s ease;
}
body.nav-active .nav__list-item:nth-child(0) {
 transition-delay: 0.7s;
}
body.nav-active .nav__list-item:nth-child(1) {
 transition-delay: 0.8s;
}
body.nav-active .nav__list-item:nth-child(2) {
 transition-delay: 0.9s;
}
body.nav-active .nav__list-item:nth-child(3) {
 transition-delay: 1s;
}
body.nav-active .nav__list-item:nth-child(4) {
 transition-delay: 1.1s;
}
body.nav-active .nav__list-item:nth-child(5) {
 transition-delay: 1.2s;
}
body.nav-active .nav__list-item:nth-child(6) {
 transition-delay: 1.3s;
}
body.nav-active .nav__list-item:nth-child(7) {
 transition-delay: 1.4s;
}
body.nav-active .nav__list-item:nth-child(8) {
 transition-delay: 1.5s;
}
body.nav-active .nav__list-item:nth-child(9) {
 transition-delay: 1.6s;
}
body.nav-active .nav__list-item:nth-child(10) {
 transition-delay: 1.7s;
}

.switch-wrap {
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 10;
    transform: translateY(-50%);
	width: 100%;
	transition: all 500ms linear; 
	margin: 0 auto;
	text-align: center;
	color: #e2e2e2;
}
.switch-wrap h1 {
	font-weight: 900;
	font-size: 46px;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 40px;
	transition: all 300ms linear; 
}
@media screen and (max-width: 580px){
  .switch-wrap h1 {
    font-size: 32px;
  }
}
.switch-wrap p {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 1;
	color: #8167a9;
	text-align: center;
	margin-top: 15px;
}
.switch-wrap p span {
	position: relative;
}
.switch-wrap p span:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 2px;
	background-color: #fff;
	left: 0;
	bottom: -4px;
	transition: all 300ms linear; 
}
.switch-wrap p span:nth-child(2):before {
	opacity: 0;
}
#switch,
#circle {
	cursor: pointer;
	transition: all 300ms linear; 
} 
#switch {
	width: 60px;
	height: 8px;
	margin: 0 auto;
	text-align: center;
	border: 2px solid #000;
	border-radius: 27px;
	background: #8167a9;
	position: relative;
	display: inline-block;
}
#circle {
	position: absolute;
	top: -11px;
	left: -13px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
	background: #fff;
}
.switched {
	border-color: #8167a9 !important;
	background: #000 !important;
}
.switched #circle {
	left: 43px;
	background: #000;
}

/* hamburger */


#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 9px;
}

#nav-icon3 span:nth-child(4) {
  top: 18px;
}

#nav-icon3.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}



#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  height: 2px;
  width: 30px;
  display: block;
  background-color: #fff;
  margin-bottom: 7px;
  position: absolute;
}
