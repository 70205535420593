/* Welcome to */
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.BCA-landing-page {
    /* transition not working */
    transition:all 0.3s ease;
}

.BCA-landing-page .landing-note {
    width: 80%;
    margin: auto;
}

.BCA-landing-page .landing-note p {
    font-weight: 600;
    font-size: .75em;
    letter-spacing: 1px;
    line-height: 1;
    color: #c4c3c9ba;
    text-align: center;
    margin-top: 15px;
    font-style: italic;
}